import { useState } from "react";
import "./App.css";
import TodoList from "./components/Todos/TodoList";

function App() {
  const [fldValue, setFldValue] = useState("---");
  //const [data, setdata] = useState({ user: "test", pass: "secret" });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    //TodoList.AddList(fldValue);
    alert(fldValue);
  };

  function handleInputField(input) {
    setFldValue(input.target.value);
  }

  //["fldValue"] = fldValue + "!";

  return (
    <div className="App">
      <form onSubmit={handleFormSubmit} hidden={true}>
        <h1>TEST</h1>
        <label>
          ToDo:
          <input
            type="text"
            value={fldValue}
            onChange={handleInputField}
          ></input>
          <button type="submit"> Submit</button>
          <button> B1</button>
        </label>
      </form>
      {TodoList()}
    </div>
  );
}

export default App;

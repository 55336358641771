import styles from "../Todos/Todo.module.css";

function MyButton(props) {
  return (
    <p>
      <button className={styles.myButton} onClick={props.onClick}>
        {props.txt}
      </button>
    </p>
  );
}

export default MyButton;

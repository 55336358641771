import { useState, useEffect } from "react";
import Todo from "./Todo.js";
import styles from "../Todos/Todo.module.css";

//const todoTab = ["Task1", "Task2", "Task3"];
//const texts = ["aaaa", "bbbb", "ccccc", "dddddd"];

function TodoList() {
  const [fldValue, setFldValue] = useState("");
  const [todoTab, setTodoTab] = useState([]);
  /* const [todoTab, setTodoTab] = useState([
    { name: "Федя", url: "/cat/jw7B8lQrPUZAHEy1" },
    { name: "Дуся", url: "/cat/eWpWwlm7VGFhhGRA" },
    { name: "Малыш", url: "/cat/q0wsZgEtQNLV4BC1" },
    { name: "Крейзи", url: "/cat/Sxeqg0K7artsKFcg" },
  ]); */

  function AddList(newTask) {
    if (newTask === "save") {
      localStorage.setItem("todoTab", JSON.stringify(todoTab));
      return;
    }
    if (newTask === "load") {
      setTodoTab(JSON.parse(localStorage.getItem("todoTab")));
      return;
    }
    if (!newTask) {
      alert("Кого ищем?");
      return;
    }
    AddListAsync(newTask);
    return;

    todoTab.unshift(newTask);
    setTodoTab(todoTab);
    setFldValue("");

    //todoTab.shift(newTask);
    //todoTab.pop(newTask);
    //todoTab.push(newTask);
    //https://cataas.com/cat?json=true
  }

  const getData = async (url) => {
    const res = await fetch(url);
    const json = await res.json();
    return json;
  };

  async function AddListAsync(newTask) {
    try {
      // const data = await getData("https://cataas.com/cat?json=true");
      //todoTab.unshift({ name: newTask, url: "https://cataas.com/"+ data.url });
      const data = await getData("https://dog.ceo/api/breeds/image/random");
      todoTab.unshift({ name: newTask, url: data.message });
      setTodoTab(todoTab);
      //const data2 = await getData("https://cataas.com" + data.url);
      setFldValue("");
      localStorage.setItem("todoTab", JSON.stringify(todoTab));
    } catch (error) {
      console.log(error.message);
    }
  }

  function delListbyIndex(indexDel) {
    /*const newTab = todoTab.map((row, index) =>
      index.toString() !== indexDel.toString() ? row : undefined
    );*/
    const newTab = todoTab.filter(
      (row, index) => index.toString() !== indexDel.toString()
    );
    setTodoTab(newTab);
    localStorage.setItem("todoTab", JSON.stringify(newTab));
    //console.log(newTab);
  }

  function handleInputField(input) {
    setFldValue(input.target.value);
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    AddList(fldValue);
  };

  useEffect(() => {
    if (localStorage.getItem("todoTab"))
      setTodoTab(JSON.parse(localStorage.getItem("todoTab")));
  }, []);

  // localStorage.setItem("todoTab", JSON.stringify(todoTab));
  console.log(todoTab);
  //

  return (
    <>
      <form onSubmit={handleFormSubmit} className={styles.card}>
        <img src="https://dog.ceo/img/dog-api-logo.svg"></img>
        <p>
          <p>Моя коллекция</p>
        </p>
        <h2>
          Имя нового питомца
          <p> </p>
          <input
            type="text"
            value={fldValue}
            onChange={handleInputField}
            className={styles.myInput}
          ></input>
          <button
            type="submit"
            className={styles.myButton}
            //  onClick={handleFormSubmit}
          >
            Найти
          </button>
        </h2>
      </form>
      {!!todoTab &&
        todoTab.map((todoRaw, index) => {
          return (
            <div>
              <Todo txt={todoRaw} index={index} delFunc={delListbyIndex}></Todo>
            </div>
          );
        })}
    </>
  );
}

export default TodoList;

import MyButton from "../UI/MyButton";
import styles from "../Todos/Todo.module.css";

function Todo(props) {
  const { index, txt, delFunc } = props;

  function onClck() {
    console.log(index);
    delFunc(index);
  }

  return (
    <div>
      <div>
        <p className={styles.card}>
          <p>
            <img
              src={txt.url} //src="https://cataas.com/cat/gif" //https://cataas.com/cat/gif
              className={styles.myPic}
            ></img>
          </p>
          <h3>{txt.name}</h3>
          <MyButton txt={"Удалить"} onClick={onClck}>
            fff
          </MyButton>{" "}
        </p>
      </div>
    </div>
  );
}

export default Todo;
